<template>
  <b-modal
    id="payout-modal"
    ref="round-payrate-modal"
    hide-footer
    size="md"
    title="ตั้งค่าอัตราจ่ายเฉพาะงวด"
    @show="setData"
  >
    <form
      v-if="input"
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >

      <b-form-group
        label="เปิดใช้"
        label-class="mb-0 pb-0"
      >
        <b-form-checkbox switch size="lg" v-model="input.isAvailable" />
      </b-form-group>

      <b-form-group
        label="หวย"
        label-class="mb-0 pb-0"
      >
        <span class="text-primary">{{ roundName }}</span>
      </b-form-group>

      <b-form-group
        label="งวดวันที่"
        label-class="mb-0 pb-0"
      >
        <span class="text-primary">{{ roundDate }}</span>
      </b-form-group>

      <b-form-group
        label="อัตราจ่าย"
        label-class="mb-0 pb-0"
        v-slot="{ ariaDescribedby }"
      >
        <table class="table-payrate table table-sm">
          <thead>
            <tr>
              <th width="40%">เลข</th>
              <th width="20%">เปิดแทง</th>
              <th width="20%">จ่าย</th>
              <th width="20%">คอมมิชชั่น(%)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="bet in marketOpenBets" :key="bet.code">
              <td>
                {{ bet.text }}
              </td>
              <td>
                <b-form-checkbox switch v-model="input.rates[bet.code].isAvailable" :disabled="!input.isAvailable" />
              </td>
              <td>
                <b-input-group size="sm">
                  <b-form-input
                    size="sm"
                    v-model="input.rates[bet.code].pay"
                    :disabled="!input.isAvailable || !input.rates[bet.code].isAvailable"
                    v-on:keydown="keypress"
                    :number="true"
                    required>
                  </b-form-input>
                </b-input-group>
              </td>
              <td>
                <b-input-group size="sm">
                  <b-form-input
                    size="sm"
                    v-model="input.rates[bet.code].discount"
                    :disabled="!input.isAvailable || !input.rates[bet.code].isAvailable"
                    v-on:keydown="keypress"
                    :number="true"
                    required>
                  </b-form-input>
                </b-input-group>
              </td>
            </tr>
          </tbody>
        </table>
      </b-form-group>

      <div class="d-flex justify-content-end">
        <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="primary" size="sm" :disabled="isProcess">{{isProcess ? 'กำลังบันทึก...' : 'บันทึก'}}</b-button>
      </div>
    </form>

    <loading
      :active="isProcess"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EEEEEE"
      :height="30"
      :width="30"
      color="#E5881E"
      loader="dots"
    />
  </b-modal>
</template>
<script>
import { marketOpenBets } from '@/configs/market.config'
import moment from '@/helpers/moment'
import RoundService from '@/services/RoundService'
import cAlert from '@/helpers/alert'

export default {
  name: 'RoundPayrateModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: [Object, String],
      default: null
    }
  },
  data() {
    return {
      isProcess: false,
      input: null
    }
  },
  computed: {
    marketOpenBets() {
      return marketOpenBets
    },
    roundName() {
      if(!this.data)
        return ''

      return `${this.data.note.groupTitle} / ${this.data.note.marketTitle}`
    },
    roundDate() {
      if(!this.data)
        return ''

      return moment(this.data.roundDate.date).format("DD/MM/YYYY")
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    }
  },
  methods: {
    setData() {
      this.needReload = false
      this.input = JSON.parse(JSON.stringify(this.data?.setRate))
    },
    showModal() {
      this.$refs['round-payrate-modal'].show()
    },
    hideModal() {
      this.$refs['round-payrate-modal'].hide()
    },
    handleSubmit() {
      this.isProcess = true
      RoundService.saveSettingRate(this.data._id, this.input)
      .then((response) => {
        if(response.success) {
          this.needReload = true
          this.hideModal()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e) => {
        cAlert({
          ...e,
          title: 'ไม่สำเร็จ!',
          text: e?.message || 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    },
    keypress(event) {
      if(!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', '.'].includes(event.key)) {
        event.preventDefault()
      }
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
<style lang="scss" scoped>
#payout-modal {
  .table {
    tbody {
      tr {
        td {
          vertical-align: middle;
        }
      }
    }
  }
}
</style>
